import { Rootstate } from "../store/store";
import { useAppSelector } from "./ReduxHooks";

interface verifyRolProps {
  first?: boolean;
  second?: boolean;
}

export const useVerifyRol = () => {
  const { user } = useAppSelector((state: Rootstate) => state.auth);
  const rols = user?.rols || [];
  const firstRol = process.env.REACT_APP_FIRST || "";
  const secondRol = process.env.REACT_APP_SECOND || "";
  const verifyRol = ({ first, second }: verifyRolProps) => {
    return (
      (!!first && rols.includes(firstRol)) ||
      (!!second && rols.includes(secondRol))
    );
  };
  return { verifyRol };
};
