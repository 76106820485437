export enum ToasterTypes {
  SUCCESS = "success",
  ERROR = "error",
  WARN = "warn",
  INFO = "info",
}

export enum SeveritiesTypes {
  SUCCESS = "success",
  INFO = "info",
  WARNING = "warning",
  DANGER = "danger",
  HELP = "help",
}

export enum SportsTypes {
  RUNNING = "Correr",
  SWIMING = "Natación",
  BIKING = "Ciclismo",
  FOOTBALL = "Fútbol",
  FUTSAL = "Futsal",
  VOLLEYBALL = "Volley",
  BEACHVOLLEY = "Volley-Playa",
  BASQUETBALL = "Basquet",
  CESTOBALL = "Cesto",
  FITNESS = "Musculación",
  FUNTIONAL = "Funcional",
  CROSSFIT = "Crossfit",
  ROLLERSKATE = "Patin",
  CALISTHENICS = "Calistenia",
  TAEKWONDO = "Taekwondo",
  BOXING = "Boxeo",
  KIACKBOXING = "Kick-Boxing",
  HOCKER = "Hockey",
  PADDLE = "Pádel",
  TENNIS = "Tenis",
  GOLF = "Golf",
  ESQUI = "Esquí",
  PINGPONG = "Ping-Pong",
  OTHER = "Otro",
  NONE = "",
}

export enum IdTypes {
  DNI = "DNI",
  LC = "Libreta Cívica",
  LO = "Libreta de Enrolamiento",
  CI = "Cédula de Identidad",
  PSP = "PASAPORTE",
}

export enum Sexes {
  MALE = "M",
  FEMALE = "F",
}

export enum Themes {
  Dark = "dark",
  Light = "light",
}

export enum MonthlyFee {
  UNPAID = "Unpaid",
  DEFEATED = "Defeated",
  FOR_DEFEATED = "For_defeated",
  PAID = "Paid",
  OVERPAIMENT = "Overpayment",
  INCOMPLETE = "Incomplete",
  WITHOUT = "No_fee",
}

export enum PayMethods {
  TRANSFER = "Transferencia",
  CASH = "Efectivo",
}
