import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API}${process.env.REACT_APP_API_PRIVATE}`,
  prepareHeaders: (headers) => {
    const idToken = localStorage.getItem("idToken");
    if (idToken) {
      headers.set("Authorization", `Bearer ${idToken}`);
    }
    return headers;
  },
});

export const api = createApi({
  reducerPath: "dsAPI",

  baseQuery: baseQuery,
  /**
   * Tag types must be defined in the original API definition
   * for any tags that would be provided by injected endpoints
   */
  tagTypes: ["Auth"],

  endpoints: () => ({}),
});
