import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import {
  Home,
  Panel,
  Dashboard,
  Users,
  NewUser,
  NewInstitutions,
  Institutions,
  SeeInstitution,
  SeeUser,
  SignOut,
  Profile,
  SportMonthlyFees,
  SportRequests,
  SearchSports,
  Plans,
  EditPlan,
  NewPlan,
  Payments,
  MyInstitutions,
  SeeMyInstitution,
  PlansPublic,
  InstitutionsPublic,
  SportsPublic,
  ManageSports,
} from "../pages";
import { PageLayout } from "../layouts/PageLayout/PageLayout";
import AuthGuard from "./AuthGuard";
import { useVerifyRol } from "../hooks/useVerifyRols";

const RouterApp = () => {
  const { verifyRol } = useVerifyRol();
  return (
    <Router>
      <Routes>
        <Route path="/" element={<PageLayout />}>
          <Route index element={<Home />} />
          <Route path="sports" element={<SportsPublic />} />
          <Route path="institutions" element={<InstitutionsPublic />} />
          <Route path="plans" element={<PlansPublic />} />

          <Route element={<AuthGuard />}>
            <Route path="/panel" element={<Panel />}>
              <Route path="*" element={<Navigate to="/panel" />} />
              <Route path="" element={<Dashboard />} />
              {verifyRol({ first: true }) && (
                <>
                  <Route path="plans" element={<Plans />} />
                  <Route path="plans/:planId" element={<EditPlan />} />
                  <Route path="plans/new" element={<NewPlan />} />
                </>
              )}

              {verifyRol({ first: true, second: true }) && (
                <>
                  <Route path="manage-sports" element={<ManageSports />} />
                  <Route path="institutions" element={<Institutions />} />
                  <Route
                    path="institutions/new"
                    element={<NewInstitutions />}
                  />
                  <Route
                    path="institutions/:institutionId"
                    element={<SeeInstitution />}
                  />
                  <Route path="payments" element={<Payments />} />
                </>
              )}
              {verifyRol({ second: true }) && (
                <>
                  <Route path="users" element={<Users />} />
                  <Route path="users/new" element={<NewUser />} />
                  <Route path="users/:userId" element={<SeeUser />} />
                </>
              )}

              <Route path="profile" element={<Profile />} />
              <Route path="sports" element={<SportMonthlyFees />} />
              <Route path="sports/:userId" element={<SportRequests />} />
              <Route path="sports/search" element={<SearchSports />} />
              <Route path="my-institutions/" element={<MyInstitutions />} />
              <Route
                path="my-institutions/:institutionId"
                element={<SeeMyInstitution />}
              />
            </Route>

            <Route path="profile" element={<Profile />} />
            <Route path="signout" element={<SignOut />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  );
};
export default RouterApp;
