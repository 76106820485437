import { GetPlansQuery, GetSportsQuery } from "../../models/forms";
import { GetInstitutionsQuery, Institution } from "../../models/institutions";
import { Plan } from "../../models/plans";
import { SportFetch } from "../../models/sports";

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API}${process.env.REACT_APP_API_PUBLIC}`,
  prepareHeaders: (headers) => {
    const idToken = localStorage.getItem("idToken");
    if (idToken) {
      headers.set("Authorization", `Bearer ${idToken}`);
    }
    return headers;
  },
});

export const publicApi = createApi({
  reducerPath: "dsPublicAPI",

  baseQuery: baseQuery,
  /**
   * Tag types must be defined in the original API definition
   * for any tags that would be provided by injected endpoints
   */

  endpoints: (build) => ({
    getInstitutions: build.query<Institution[], GetInstitutionsQuery>({
      query: (data) => ({
        url: `institutions`,
        params: data,
      }),
    }),
    getSports: build.query<SportFetch[], GetSportsQuery>({
      query: (data) => ({
        url: `sports`,
        params: data,
        method: "GET",
      }),
    }),

    getPlans: build.query<Plan[], GetPlansQuery>({
      query: (data) => ({
        url: `plans`,
        params: data,
      }),
    }),
  }),
});

export const { useGetInstitutionsQuery, useGetSportsQuery, useGetPlansQuery } =
  publicApi;
