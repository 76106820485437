import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { StyleClass } from "primereact/styleclass";
import { Ripple } from "primereact/ripple";
import PagesNav from "./PagesNav";
import useSidebarOptions from "../../../hooks/useSidebarOptions";
import { useRef } from "react";
import { usePanelItems } from "../../../hooks/usePanelItems";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "../../../hooks/ReduxHooks";
import { AuthStateProps } from "../../../models/auth";
import { shallowEqual } from "react-redux";
import ThemeButton from "../../../components/ThemeButton/ThemeButton";
import { useUserOptions } from "../../../hooks/useUserOptions";

const SidebarNav = () => {
  const { user } = useAppSelector(
    (state: { auth: AuthStateProps }) => state.auth,
    shallowEqual
  );
  const { showSidebar, useWacthAndCloseSidebar, useHandleSidebarState } =
    useSidebarOptions();
  useWacthAndCloseSidebar();
  const panelItems = usePanelItems();
  const userOptions = useUserOptions();

  const btnRef1 = useRef<any>(null);
  const btnRef2 = useRef<any>(null);
  const btnRef3 = useRef<any>(null);

  const btnRefs = [
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null),
  ];

  return (
    <div className="md:hidden">
      <Button
        icon="pi pi-bars"
        rounded
        text
        aria-label="menu-nav"
        onClick={useHandleSidebarState}
      />
      <Sidebar
        visible={showSidebar}
        onHide={useHandleSidebarState}
        position="left"
      >
        <div className="flex flex-column h-full">
          <div className="h-[60%] overflow-y-auto">
            <ul className="list-none px-3 m-0">
              <li>
                <StyleClass
                  nodeRef={btnRef1}
                  selector="@next"
                  enterClassName="hidden"
                  enterActiveClassName="slidedown"
                  leaveToClassName="hidden"
                  leaveActiveClassName="slideup"
                >
                  <div
                    ref={btnRef1}
                    className="p-ripple p-3 flex align-items-center justify-content-between text-600 cursor-pointer"
                  >
                    <span className="font-bold ">Navegar</span>
                    <i className="pi pi-chevron-down "></i>
                    <Ripple />
                  </div>
                </StyleClass>
                <ul className="list-none p-0 m-0 overflow-hidden">
                  <nav className="flex flex-column">
                    <PagesNav />
                  </nav>
                </ul>
              </li>
            </ul>
            {user && (
              <ul className="list-none px-3 m-0">
                <li>
                  <StyleClass
                    nodeRef={btnRef2}
                    selector="@next"
                    enterClassName="hidden"
                    enterActiveClassName="slidedown"
                    leaveToClassName="hidden"
                    leaveActiveClassName="slideup"
                  >
                    <div
                      ref={btnRef2}
                      className="p-ripple p-3 flex align-items-center justify-content-between text-600 cursor-pointer"
                    >
                      <span className="font-bold ">Panel</span>
                      <i className="pi pi-chevron-down "></i>
                      <Ripple />
                    </div>
                  </StyleClass>
                  <ul className="list-none p-0 m-0 overflow-hidden">
                    <nav className="flex flex-column">
                      {panelItems.map((menuTitle: any, i: number) =>
                        menuTitle.items ? (
                          <ul
                            className="list-none px-3 m-0"
                            key={menuTitle.label}
                          >
                            <li>
                              <StyleClass
                                nodeRef={btnRefs[i]}
                                selector="@next"
                                enterClassName="hidden"
                                enterActiveClassName="slidedown"
                                leaveToClassName="hidden"
                                leaveActiveClassName="slideup"
                              >
                                <div
                                  ref={btnRefs[i]}
                                  className="p-ripple py-3 pl-2 flex align-items-center justify-content-between text-600 cursor-pointer"
                                >
                                  <span className="font-medium">
                                    <i className={`pr-2 ${menuTitle.icon}`}></i>
                                    {menuTitle.label}
                                  </span>
                                  <i className="pi pi-chevron-down"></i>
                                  <Ripple />
                                </div>
                              </StyleClass>
                              <ul className="list-none p-0 m-0 overflow-hidden hidden ">
                                {menuTitle.items.map((subTitle: any) => (
                                  <NavLink
                                    to={subTitle.path}
                                    key={subTitle.path}
                                  >
                                    {({ isActive }) => (
                                      <Button
                                        className={isActive ? "secondary" : ""}
                                        label={subTitle.label}
                                        icon={subTitle.icon}
                                        text
                                      />
                                    )}
                                  </NavLink>
                                ))}
                              </ul>
                            </li>
                          </ul>
                        ) : (
                          <NavLink to={menuTitle.path} key={menuTitle.path}>
                            {({ isActive }) => (
                              <Button
                                className={isActive ? "secondary" : ""}
                                label={menuTitle.label}
                                icon={menuTitle.icon}
                                text
                              />
                            )}
                          </NavLink>
                        )
                      )}
                    </nav>
                  </ul>
                </li>
              </ul>
            )}
          </div>
          <div className="mt-auto">
            <hr className="mb-3 mx-3 border-top-1 border-none surface-border" />
            <ul className="list-none px-3 m-0">
              <li>
                <StyleClass
                  nodeRef={btnRef3}
                  selector="@next"
                  enterClassName="hidden"
                  enterActiveClassName="slidedown"
                  leaveToClassName="hidden"
                  leaveActiveClassName="slideup"
                >
                  <div
                    ref={btnRef3}
                    className="p-ripple p-3 flex align-items-center justify-content-between text-600 cursor-pointer"
                  >
                    <span className="font-bold ">Usuario</span>
                    <i className="pi pi-chevron-down "></i>
                    <Ripple />
                  </div>
                </StyleClass>
                <ul className="px-3 list-none p-0 m-0 overflow-hidden hidden ">
                  {userOptions.map(
                    (userOp) =>
                      !userOp.separator &&
                      userOp.label !== "Panel" && (
                        <Button
                          key={userOp.label}
                          label={userOp.label}
                          icon={userOp.icon}
                          onClick={userOp.command}
                          text
                        />
                      )
                  )}
                </ul>
              </li>
            </ul>
            <ul className="list-none px-3 m-0">
              <ThemeButton />
            </ul>
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export default SidebarNav;
